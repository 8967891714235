.preview-modal {
  --width: min(80%, 310px);
  --height: 85%;
  --border-radius: 15px;
  padding-bottom: 15%;

  ::-webkit-scrollbar {
    display: none;
  }
}
